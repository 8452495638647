.container {
  width: 100%;
}

.margin6 {
  margin-right: 3px;
}

.sv_header {
  background-color: white;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  background-color: white;
}

.sv_main button {
  background-color: #8c1f27;
}

/* .svd_container .svd_commercial_container {
  display: none;
}

.sa-commercial {
  display: none;
} */

#editor-container {
  height: 200px;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  min-width: 15px;
}

.ql-editor {
  min-height: 200px;
}

.sv_main .sv_nav {
  right: 0px;
  float: right;
  margin: 7px 0px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}